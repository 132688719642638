<template>
  <div>
    <button @click="browserLocate" :disabled="locatingBrowser" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg" style="min-width: 223.7px; min-height: 37px;">
      <div v-if="!locatingBrowser">
        <LocationMarkerIcon class="inline -mt-1 -ml-1 mr-2 h-5 w-5 text-white" />Find Therapists Near Me
      </div>
      <div v-else class="absolute" style="top: 27px;">
        <AppSpinnerDualRing class="py-2 px-4"/>
      </div>
    </button>
  </div>
</template>

<script>
import { LocationMarkerIcon } from '@heroicons/vue/outline'
export default ({
  name: 'MapControls',
  components: {
    LocationMarkerIcon
  },
  methods: {
    browserLocate () {
      this.$store.dispatch('browserLocate')
    }
  },
  computed: {
    locatingBrowser () {
      return this.$store.state.locatingBrowser
    }
  }
})
</script>

<style scoped>

</style>
