import { createStore } from 'vuex'
import getters from '@/store/getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'

export default createStore({
  state: {
    authObserverUnsubscribe: null,
    authUserUnsubscribe: null,
    allListingsUnsubscribe: null,
    unsubscribes: [],
    authId: null,
    allListingsSnapshotFetched: null,
    allListings: [],
    listings: [],
    listingsChunk: [],
    locatingBrowser: false,
    browserLocation: { lat: 40.0141127, lng: -105.2370652 },
    browserZipcode: 80026,
    mapCenter: { lat: 40.0141127, lng: -105.2370652 },
    zipcode: 80026,
    radius: 10,
    zoom: 10.3,
    upsertingCsv: false,
    uploadedCsvLength: 0,
    processCount: 0,
    geocodeCount: null,
    upsertCount: null
  },
  getters,
  actions,
  mutations
})
