<template>
  <div>
    <div>
      <label :for="name">{{label}}</label>
    </div>
    <div>
      <VeeField
        :name="name"
        :label="label"
        :modelValue="modelValue"
        :id="name"
        class=" bg-gray-50 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        v-bind="$attrs"
      />
    </div>
    <div>
      <VeeErrorMessage :name="name" class="form-error" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppFormField',
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    modelValue: { type: [String, Number], default: '' }
  }
}
</script>

<style scoped>
.form-error {
    background: #f4d3d9;
    color: #C82543;
    font-size: 0.8em;
    float: left;
    border-radius: 100px;
    padding: 6px 20px;
    margin-top: 10px;
}
</style>
