<template>
  <div class="">
    <div v-if="!authUser">
      <button @click="signInWithGoogle" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
        <i class="fa fa-google fa-btn"></i>Admin Sign in with Google
      </button>
    </div>
    <div v-else>
      <button @click="signOut" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
        <i class="fa fa-google fa-btn"></i>Sign Out
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  methods: {
    async signInWithGoogle () {
      await this.$store.dispatch('signInWithGoogle')
      this.$router.push('/')
    },

    async signOut () {
      await this.$store.dispatch('signOut')
      this.$router.push('/')
    }
  },
  computed: {
    authUser () {
      return this.$store.getters.authUser
    }
  }
}
</script>

<style scoped>
  button{ font-size: 10px; }

</style>
