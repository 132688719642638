import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate'
import { required } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
export default (app) => {
  defineRule('required', required)
  defineRule('state', (value) => {
    if (!value && !value.trim()) return true
    return (/[A-Z].*[A-Z]/).test(value)
  })
  defineRule('zipcode', (value) => {
    if (!value && !value.trim()) return true
    return (/\b\d{5}\b/g).test(value)
  })
  defineRule('phone', (value) => {
    if (!value && !value.trim()) return true
    return (/^(1-)?\d{3}-\d{3}-\d{4}$/).test(value)
  })
  defineRule('guid', (value) => {
    if (!value && !value.trim()) return true
    return (/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/).test(value)
  })

  configure({
    generateMessage: localize('en', {
      messages: {
        required: '{field} is required',
        phone: 'Number must be a valid format',
        state: '{field} must be a valid format',
        zipcode: '{field} must be a valid format',
        guid: '{field} must be a valid format'
      }
    })
  })

  app.component('VeeForm', Form)
  app.component('VeeField', Field)
  app.component('VeeErrorMessage', ErrorMessage)
}
