<template>
  <section id="bottom-bg">
    <div id="bottom" class="container_24">
      <div class="bottom-content-padding">
        <div id="bottom_1" class="one_third">
          <div class="column-content-wrapper">
            <div class="bottom-col-content widget_text substitute_widget_class">
              <h3 class="bottom-col-title">Contact Info</h3>
              <div class="textwidget">
                <div style="color:#EEEEE; font-style:;">
                  Klose Training &amp; Consulting, LLC<br>
                  307 S. Public Road,<br>
                  Lafayette, CO 80026<br>
                  <br>
                  <strong>Phone: 303-245-0333</strong>
                </div>
              </div>
            </div>
          </div>
        </div><!-- end bottom_1 -->

        <div id="bottom_2" class="one_third">
          <div class="column-content-wrapper">
            <div class="bottom-col-content widget_recent_entries custom-formatting">
              <h3 class="bottom-col-title">Social</h3>
              <div class="social-icons">
                <a href="https://www.facebook.com/klosetraining" title="Facebook" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2019/09/Facebook_iOS.png" alt="Facebook" class="alignnone size-full wp-image-14225" width="45" height="45" border="0"></a>
                <a href="https://twitter.com/klose_training" title="Twitter" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2019/09/Twitter_iOS.png" alt="Twitter" class="alignnone size-full wp-image-14225" width="45" height="45" border="0"></a>
                <a href="https://www.youtube.com/channel/UCxYMOFgbSkn3VtvNUr3Zmww/" title="YouTube" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2019/09/YouTube_iOS.png" alt="YouTube" class="alignnone size-full wp-image-14225" width="45" height="45"></a>
                <a href="mailto:info@klosetraining.com" title="E-mail"><img src="https://klosetraining.com/wp-content/uploads/2019/09/Mail_iOS.png" alt="email" class="alignnone size-full wp-image-14225" width="45" height="45" border="0"></a>
                <a href="https://www.linkedin.com/company/klose-training-&amp;-consulting-lymphedema-care" title="LinkedIn" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2019/09/LinkedIn_iOS.png" alt="LinkedIn" class="alignnone size-full wp-image-14225" width="45" height="45"></a>
              </div>
            </div>
          </div>
        </div><!-- end bottom_2 -->

        <div id="bottom_3" class="one_third last_column">
          <div class="column-content-wrapper">
            <div class="bottom-col-content widget_text substitute_widget_class">
              <h3 class="bottom-col-title">Partners</h3>
              <div class="partner-icons">
                <a href="http://lymphaticnetwork.org/" title="LE&amp;RN" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2017/01/LEARN_sm2.png" alt="LE&amp;RN" border="0"></a>
                <a href="https://www.alfp.org/" title="ALFP" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2017/01/alfp-only_sm.png" alt="ALFP" width="100" height="54" border="0"></a>
                <a href="https://lymphnet.org/" title="NLN" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2019/02/nln_only.png" alt="NLN" width="100" height="54" border="0"></a>
                <a href="https://www.foeldiklinik.de/home-en-gb/" title="Földi Klinik" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2017/01/Foldi-Klinik_sm.png" alt="Földi Klinik" width="85" height="54" border="0"></a>
                <a href="http://www.foeldischule.de/" title="Földi Schule" target="_blank" rel="noopener"><img src="https://klosetraining.com/wp-content/uploads/2017/01/Foldi-Schule_sm.png" alt="Földi Schule" width="85" height="54" border="0"></a>
              </div>
            </div>
          </div>
        </div><!-- end bottom_3 -->
      </div><!-- end bottom-content-padding -->
    </div><!-- end bottom -->
  </section>

  <footer id="footer-bg">
    <div id="footer" class="container_24 footer-top">
      <div id="footer_text" class="grid_16">
        &copy; {{new Date().getFullYear()}} <strong>Klose Training &amp; Consulting LLC </strong>
      </div>
      <SignIn/>
    </div>
  </footer>

</template>

<script>
import SignIn from '@/components/SignIn'
export default {
  name: 'Footer',
  components: {
    SignIn
  }
}
</script>

<style scoped>
  .partner-icons,
  .social-icons {
    display: flex !important;
  }
</style>
