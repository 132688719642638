import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import firebase from '@/helpers/firebase'
import firebaseConfig from '@/config/firebase'
import VeeValidatePlugin from '@/plugins/VeeValidatePlugin'
import Vue3Pagination from '@/plugins/Vue3Pagination'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import '@/assets/style.css'
import '@/assets/tailwind.css'
import 'nprogress/nprogress.css'
import 'vue3-circle-progress/dist/circle-progress.css'

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// Initialize App
const kloseDirectoryApp = createApp(App)
kloseDirectoryApp.use(router)
kloseDirectoryApp.use(store)
kloseDirectoryApp.use(VeeValidatePlugin)
kloseDirectoryApp.use(Vue3Pagination)

kloseDirectoryApp.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_JAVASCRIPT_API_KEY
  }
})

// Add all global components under '/base' to the app dynamically
const requireComponent = require.context('./base', true, /App[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(function (fileName) {
  let baseComponentConfig = requireComponent(fileName)
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig
  const baseComponentName = baseComponentConfig.name || (
    fileName
      .replace(/^.+\//, '')
      .replace(/\.\w+$/, '')
  )
  kloseDirectoryApp.component(baseComponentName, baseComponentConfig)
})

kloseDirectoryApp.mount('#app')
