<template>
  <div class="col-full text-center">
    <h1>Not Found</h1>
    <router-link :to="{name: 'Home'}">Back to the homepage</router-link>
  </div>
</template>

<script>
import asynDataStatus from '@/mixins/asyncDataStatus'
export default {
  name: 'NotFound',
  mixins: [asynDataStatus],
  created () {
    this.asyncDataStatus_fetched()
  }
}
</script>

<style scoped>

</style>
