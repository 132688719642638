<template>
  <div>
    <div>
      <Header/>
    </div>
    <div>
      <router-view v-show="showPage" @ready="onPageReady"/>
      <AppSpinner v-show="!showPage"/>
    </div>
    <div>
      <Footer/>
    </div>
    <div>
      <AppNotifications/>
    </div>
  </div>
</template>

<script>
import AppNotifications from '@/base/AppNotifications'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NProgress from 'nprogress'
export default {
  name: 'App',
  components: {
    AppNotifications,
    Header,
    Footer
  },
  data () {
    return {
      showPage: false
    }
  },
  methods: {
    onPageReady () {
      this.showPage = true
      NProgress.done()
    }
  },
  created () {
    NProgress.configure({
      speed: 200,
      minimum: 0.25,
      showSpinner: false
    })
    this.$router.beforeEach(() => {
      this.showPage = false
      NProgress.start()
    })
  }
}
</script>

<style scoped>

</style>
