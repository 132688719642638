<template>
  <div class="flex mb-10" v-if="authUser">
    <ListControls/>
  </div>

  <header>
    <div v-if="!loadingAllListings">
      <h5 class="text-lg">{{hitCount}}</h5>
    </div>
  </header>

  <section class="listings-container mx-auto">
    <div v-if="!loadingAllListings">
      <div v-for="(listing) in listingsByPage" :key="listing.guid" class="listing-row mx-auto justify-between mb-3 border border-solid border-gray-200 rounded-md flex-wrap md:flex md:flex-no-wrap">
        <div class="p-3 md:p-6 text-gray-500 bg-gray-100 border-b border-solid border-gray-200 md:border-r md:border-b-0 md:flex-1 ">
          <span class="font-bold text-green-400 text-lg">{{listing.contact}}</span>
          <br />
          {{listing.title}}
        </div>
        <div class="px-3 pt-3 md:p-6 text-gray-500 border-r md:flex-1">
          <span class="font-bold">{{listing.company}}</span>
          <br>
          <span class="italic">{{listing.department}}</span>
        </div>
        <div class="px-3 pb-3 md:p-6 text-gray-500 border-r md:flex-1">
          {{listing.address1}}
          <br>
          {{`${listing.city}, ${listing.state} ${listing.zipcode}`}}
          <br>
          {{listing.phone}}
        </div>
        <div v-if="authUser" class="px-3 pb-3 md:p-6 text-gray-500 border-gray-200 md:flex-1">
          <ListingControls :listing="listing"/>
        </div>
      </div><!-- /listing-row -->
    </div>
    <div v-else>
      <AppSpinner/>
    </div>
  </section><!-- /listings-container -->

  <div v-if="listingsExist && !loadingAllListings" class="flex justify-center">
    <v-pagination v-model="page" :pages="totalPages" active-color="#3B82F6"/>
  </div>

</template>

<script>
import ListControls from '@/components/ListControls'
import ListingControls from '@/components/ListingControls'
import listingsByPage from '@/mixins/listingsByPage'
export default {
  name: 'List',
  components: {
    ListControls,
    ListingControls
  },
  mixins: [listingsByPage(20)],
  data () {
    return {
      perPage: 20
    }
  },
  computed: {
    authUser () {
      return this.$store.getters.authUser
    },
    hitCount () {
      let startItem
      if (this.$store.state.listings.length === 0) {
        return 'No results'
      } else {
        startItem = ((this.page * this.perPage) - (this.perPage - 1))
      }
      let endItem = ((startItem - 1) + this.perPage)
      if (endItem > this.$store.state.listings.length) {
        endItem = this.$store.state.listings.length
      }
      return `Showing ${startItem} - ${endItem} of ${this.$store.state.listings.length}`
    },
    listingsExist () {
      return this.$store.state.listingsChunk.length > 0
    },
    loadingAllListings () {
      const loadingAllListings = !(this.$store.state.allListingsSnapshotFetched === null || this.$store.state.allListingsSnapshotFetched === true)
      return loadingAllListings
    }
  }
}
</script>

<style scoped>

</style>
