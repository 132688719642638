<template>
  <div v-show="!upsertingCsvStatic">
    <VueCsvImport
        v-model="csv"
        :key="key"
        :text="{
          errors: {
            fileRequired: 'A file is required',
            invalidMimeType: 'Invalid file type'
          },
          toggleHeaders: 'File has headers',
          submitBtn: 'Submit CSV',
          fieldColumn: 'Field',
          csvColumn: 'Column'
        }"
        :fields="{
          company: {
            required: true,
            label: 'Company'
          },
          address1: {
            required: true,
            label: 'Address 1'
          },
          city: {
            required: true,
            label: 'City'
          },
          state: {
            required: true,
            label: 'State'
          },
          zipcode: {
            required: true,
            label: 'ZIP Code'
          },
          country: {
            required: true,
            label: 'Country'
          },
          phone: {
            required: true,
            label: 'Phone'
          },
          guid: {
            required: true,
            label: 'GUID ID'
          },
          contact: {
            required: true,
            label: 'Contact'
          },
          firstname: {
            required: true,
            label: 'First Name'
          },
          lastname: {
            required: true,
            label: 'Last Name'
          },
          title: {
            required: true,
            label: 'Title'
          },
          department: {
            required: true,
            label: 'Department'
          },
        }"
      >
      <VueCsvInput class="file-input"/>
      <VueCsvErrors/>
      <div class="hidden">
        <VueCsvMap/>
      </div>
      </VueCsvImport>
  </div>
  <div v-show="upsertingCsvStatic" class="text-3xl">
    <CircleProgress
      :percent="percent"
      :is-gradient="false"
      :show-percent="true"
    />
  </div>
</template>

<script>
import { VueCsvImport, VueCsvInput, VueCsvErrors, VueCsvMap } from 'vue-csv-import'
import CircleProgress from 'vue3-circle-progress'
export default {
  name: 'UploadCsv',
  components: {
    VueCsvImport,
    VueCsvInput,
    VueCsvErrors,
    VueCsvMap,
    CircleProgress
  },
  data () {
    return {
      csv: [],
      key: 0
    }
  },
  methods: {
    appendPercentSymbol () {
      const elements = document.getElementsByClassName('percentSymbolSpan')
      while (elements.length > 0) elements[0].remove()
      const percentSymbolSpan = document.createElement('span')
      percentSymbolSpan.innerHTML = '%'
      percentSymbolSpan.classList.add('percentSymbolSpan')
      document.getElementsByClassName('vue3-circular-progressbar')[0].append(percentSymbolSpan)
    },
    async submit () {
      this.appendPercentSymbol()
      this.$store.commit('setUpsertingCsv', true)
      this.$store.commit('setUploadedCsvLength', (this.csv.length - 1))
      const geoCsv = await this.$store.dispatch('geocodeCsv', this.csv)
      this.$store.dispatch('upsertGeoCsv', geoCsv)
      this.key++
    }
  },
  computed: {
    upsertingCsvStatic () {
      return this.$store.state.processCount > 0
    },
    percent () {
      if (this.$store.state.uploadedCsvLength > 0) {
        const loadPercent = Math.ceil((parseInt(this.$store.state.processCount) / (parseInt(this.$store.state.uploadedCsvLength) * 2)) * 100)
        return loadPercent
      } else {
        return parseInt(0)
      }
    }
  }
}
</script>

<style scoped>

</style>
