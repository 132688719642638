<template>
  <div>
    <button @click="toggleUploadCsvModal" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"  style="min-width: 141px; min-height: 37px;">
      <div v-if="!upsertingCsvDynamic">
        <UploadIcon class="inline -mt-1 -ml-1 mr-2 h-5 w-5 text-white" />
        Upload CSV
      </div>
      <div v-else>
        <AppUploadLoader class="absolute -ml-7 mr-10 h-5 w-5"/>
        <p class="inline -mr-7">Upload CSV</p>
      </div>
    </button>
    <button @click="createNewListing" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 ml-3 rounded-lg">
      <UserAddIcon class="inline -mt-1 -ml-1 mr-2 h-5 w-5 text-white" />
      New Listing
    </button>
  </div>
  <div>
    <UploadCsvModal :open="uploadCsvModal" @toggleUploadCsvModal="toggleUploadCsvModal"/>
    <EditListingModal :listing="initListingObj" :open="editListingModal" @toggleEditListingModal="toggleEditListingModal"/>
  </div>
</template>

<script>
import UploadCsvModal from '@/components/UploadCsvModal'
import EditListingModal from '@/components/EditListingModal'
import firebase from '@/helpers/firebase'
import { UploadIcon, UserAddIcon } from '@heroicons/vue/outline'
export default {
  name: 'ListControls',
  components: {
    UploadCsvModal,
    EditListingModal,
    UploadIcon,
    UserAddIcon
  },
  data () {
    return {
      uploadCsvModal: false,
      editListingModal: false,
      initListingObj: ''
    }
  },
  methods: {
    toggleUploadCsvModal () {
      this.uploadCsvModal = !this.uploadCsvModal
    },
    toggleEditListingModal () {
      this.editListingModal = !this.editListingModal
    },
    createNewListing () {
      this.initListingObj = {
        company: '',
        address1: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        phone: '',
        guid: '',
        contact: '',
        firstname: '',
        lastname: '',
        title: '',
        department: '',
        geocode: { lat: 0, lng: 0, geohash: 0 },
        timestamp: firebase.firestore.Timestamp.now()
      }
      this.toggleEditListingModal()
    }
  },
  computed: {
    upsertingCsvDynamic () {
      return this.$store.state.upsertingCsv
    }
  }
}
</script>

<style scoped>

</style>
