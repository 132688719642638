<template>
  <div>
    <button @click="toggleEditListingModal" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 ml-3 rounded-lg">
      <PencilAltIcon class="inline -mt-1 -ml-1 mr-2 h-5 w-5 text-white" />
      Edit
    </button>
    <button @click="toggleDeleteListingModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mx-3 rounded-lg">
      <TrashIcon class="inline -mt-1 -ml-1 mr-2 h-5 w-5 text-white" />
      Delete
    </button>
  </div>
  <div>
    <EditListingModal :listing="listing" :open="editListingModal" @toggleEditListingModal="toggleEditListingModal"/>
  </div>
  <div>
    <DeleteListingModal :listing="listing" :open="deleteListingModal" @toggleDeleteListingModal="toggleDeleteListingModal"/>
  </div>
</template>

<script>
import EditListingModal from '@/components/EditListingModal'
import DeleteListingModal from '@/components/DeleteListingModal'
import { PencilAltIcon, TrashIcon } from '@heroicons/vue/outline'
export default {
  name: 'ListingControls',
  components: {
    EditListingModal,
    DeleteListingModal,
    PencilAltIcon,
    TrashIcon
  },
  props: {
    listing: { type: Object, required: true }
  },
  data () {
    return {
      editListingModal: false,
      deleteListingModal: false
    }
  },
  methods: {
    toggleEditListingModal () {
      this.editListingModal = !this.editListingModal
    },
    toggleDeleteListingModal () {
      this.deleteListingModal = !this.deleteListingModal
    }
  }
}
</script>

<style scoped>

</style>
