import firebase from '@/helpers/firebase'
import * as geofire from 'geofire-common'
import axios from 'axios'
import chunk from 'lodash/chunk'
import useNotifications from '@/composables/useNotifications'
const { addNotification } = useNotifications()

export default {
  initAuthentication (context) {
    return new Promise((resolve, reject) => {
      try {
        if (context.state.authObserverUnsubscribe) context.state.authObserverUnsubscribe()
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
          context.dispatch('unsubscribeAuthUserSnapshot')
          if (user) {
            const userEmail = user.email.split('@')[1]
            if (userEmail === 'klosetraining.com' || userEmail === 'dd9.com') {
              addNotification({ message: `Welcome ${user.displayName}!`, timeout: '5000', type: 'info' })
              await context.dispatch('fetchAuthUserSnapshot')
              resolve()
            } else {
              user.delete()
              context.dispatch('signOut', 'unauthorized')
              resolve()
            }
          } else {
            resolve(null)
          }
        })
        context.commit('setAuthObserverUnsubscribe', unsubscribe)
      } catch (error) {
        console.log(error)
        addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
        reject(error)
      }
    })
  },

  async fetchAuthUserSnapshot (context) {
    try {
      const userId = await firebase.auth().currentUser?.uid
      if (!userId) return
      const unsubscribe = firebase.firestore().collection('users').doc(userId).onSnapshot()
      context.commit('setAuthId', userId)
      context.commit('setAuthUserUnsubscribe', unsubscribe)
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async unsubscribeAuthUserSnapshot (context) {
    try {
      if (context.state.authUserUnsubscribe) {
        context.state.authUserUnsubscribe()
        context.commit('setAuthUserUnsubscribe', null)
      }
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async unsubscribeAllSnapshots (context) {
    try {
      if (context.state.unsubscribes.length > 0) {
        context.state.unsubscribes.forEach(unsubscribe => unsubscribe())
        context.commit('clearAllUnsubscribes')
      }
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async signInWithGoogle (context) {
    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      await firebase.auth().signInWithPopup(provider)
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async signOut (context, unauthorized = null) {
    try {
      await firebase.auth().signOut()
      context.commit('setAuthId', null)
      if (!unauthorized) {
        addNotification({ message: 'Signed Out', timeout: 5000, type: 'info' })
      } else {
        addNotification({ message: 'Unauthorized Login Email', timeout: 5000, type: 'error' })
      }
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  fetchAllListingsSnapshot (context) {
    return new Promise((resolve, reject) => {
      try {
        if (context.state.allListingsUnsubscribe) context.state.allListingsUnsubscribe()
        const unsubscribe = firebase.firestore().collection('listings').orderBy('contact', 'asc').onSnapshot((snapshot) => {
          const docs = snapshot.docs.map(doc => doc.data())
          context.commit('setAllListings', docs)
          context.commit('setAllListingsSnapshotFetched', true)
          resolve()
        })
        context.commit('setAllListingsSnapshotUnsubscribe', unsubscribe)
      } catch (error) {
        console.log(error)
        addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
        reject(error)
      }
    })
  },

  listingsByPage (context, { listings, page, perPage }) {
    try {
      if (listings.length === 0) {
        context.commit('clearListingsChunk')
        return
      }
      const listingsChunks = chunk(listings, perPage)
      const listingsChunk = listingsChunks[page - 1]
      context.commit('setListingsChunk', listingsChunk)
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async listingsBySearch (context, { searchZipcodeInput, searchNameInput }) {
    try {
      if (searchZipcodeInput && searchNameInput.trim() === '') {
        if (context.state.radius > 999) context.commit('setLocalMap')
        axios.post(`https://maps.googleapis.com/maps/api/geocode/json?address=${searchZipcodeInput}&key=${process.env.VUE_APP_GEOCODE_API_KEY}`)
          .then(async (res) => {
            const lat = res.data.results[0]?.geometry?.location.lat || 0
            const lng = res.data.results[0]?.geometry?.location.lng || 0
            await context.commit('setMapCenter', { coords: { latitude: lat, longitude: lng } })
            await context.dispatch('geohashRadius')
          })
          .catch((error) => {
            addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
          })
      }
      if (searchZipcodeInput.trim() === '' && searchNameInput.trim() === '') {
        if (context.state.allListingsSnapshotFetched !== true) {
          await context.commit('setAllListingsSnapshotFetched', false)
          await context.dispatch('fetchAllListingsSnapshot')
        }
        context.commit('setListings', context.state.allListings)
        context.commit('setGlobalMap')
      }
      if (searchZipcodeInput.trim() === '' && searchNameInput) {
        if (context.state.allListingsSnapshotFetched !== true) {
          await context.commit('setAllListingsSnapshotFetched', false)
          await context.dispatch('fetchAllListingsSnapshot')
        }
        const searchRegex = new RegExp(('^.*' + searchNameInput + '.*$'), 'i')
        const filteredListings = context.state.allListings.filter(listing => listing.contact?.match(searchRegex))
        context.commit('setListings', filteredListings)
        context.commit('setGlobalMap')
      }
      if (searchZipcodeInput && searchNameInput) {
        if (context.state.radius > 999) context.commit('setLocalMap')
        axios.post(`https://maps.googleapis.com/maps/api/geocode/json?address=${searchZipcodeInput}&key=${process.env.VUE_APP_GEOCODE_API_KEY}`)
          .then(async (res) => {
            const lat = res.data.results[0]?.geometry?.location.lat || 0
            const lng = res.data.results[0]?.geometry?.location.lng || 0
            await context.commit('setMapCenter', { coords: { latitude: lat, longitude: lng } })
            await context.dispatch('geohashRadius')
            const searchRegex = new RegExp(('^.*' + searchNameInput + '.*$'), 'i')
            const filteredListings = this.state.listings.filter(listing => listing.contact?.match(searchRegex))
            context.commit('setListings', filteredListings)
          })
          .catch((error) => {
            console.log(error)
            addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
          })
      }
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async geocodeCsv (context, csv) {
    try {
      await context.commit('setGeocodeCount', 0)
      const geoCsv = []
      let emptyLines = true
      csv.shift()
      for (const i in csv) {
        await context.commit('incrementProcessCount')
        const entry = csv[i]
        if (emptyLines) {
          if (entry.address1 === '' && entry.city === '' && entry.company === '' && entry.contact === '' && entry.country === '' && entry.department === '' && entry.firstname === '' && entry.guid === '' && entry.lastname === '' && entry.phone === '' && entry.state === '' && entry.title === '' && entry.zipcode === '') {
            addNotification({ message: 'Empty line detected in this CSV file. Your CSV file will still be uploaded but the loading count and percentage may be bugged.', timeout: null, type: 'error' })
            emptyLines = false
          }
        }
        const address1 = entry.address1?.replace(/\s+/g, '+')
        const city = entry.city?.replace(/\s+/g, '+')
        const state = entry.state?.replace(/\s+/g, '+')
        const zipCode = entry.zipcode?.replace(/\s+/g, '+')
        const country = entry.country?.replace(/\s+/g, '+')
        const addressUrl = `${address1},${city},${state},${zipCode},${country}`
        if (addressUrl.length < 5) {
          geoCsv[i] = { ...entry, geocode: { lat: 0, lng: 0, geohash: 0 } }
          continue
        }
        const dbEntryPromise = await firebase.firestore().collection('listings').doc(csv[i].guid).get()
        if (dbEntryPromise.exists) {
          const dbEntry = dbEntryPromise.data()
          const dbEntryAddress1 = dbEntry.address1?.replace(/\s+/g, '+')
          const dbEntryCity = dbEntry.city?.replace(/\s+/g, '+')
          const dbEntryState = dbEntry.state?.replace(/\s+/g, '+')
          const dbEntryZipCode = dbEntry.zipcode?.replace(/\s+/g, '+')
          const dbEntryCountry = dbEntry.country?.replace(/\s+/g, '+')
          const dbEntryAddressUrl = `${dbEntryAddress1},${dbEntryCity},${dbEntryState},${dbEntryZipCode},${dbEntryCountry}`
          if (addressUrl === dbEntryAddressUrl) {
            geoCsv[i] = { ...dbEntry }
            continue
          }
        }
        await axios.post(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressUrl}&key=${process.env.VUE_APP_GEOCODE_API_KEY}`)
          .then(async (res) => {
            await context.commit('incrementGeocodeCount')
            const lat = res.data.results[0]?.geometry?.location.lat || 0
            const lng = res.data.results[0]?.geometry?.location.lng || 0
            const hash = geofire.geohashForLocation([lat, lng])
            geoCsv[i] = { ...entry, geocode: { lat, lng, geohash: hash } }
          })
          .catch((error) => {
            console.log(error)
            console.log(`on CSV entry ${parseInt(i) + 2}:`)
            console.log(csv[i])
            addNotification({ message: `Geocode error on ${csv[i].contact}, please simplify the address once the upload is complete.`, timeout: null, type: 'error' })
            geoCsv[i] = { ...entry, geocode: { lat: 0, lng: 0, geohash: 0 } }
          })
      }
      return geoCsv
    } catch (error) {
      console.log(error)
      addNotification({ message: `Geocode Error: ${error}`, timeout: null, type: 'error' })
    }
  },

  async geocodeListing (context, listing) {
    try {
      let geoListing = {}
      const address1 = listing.address1?.replace(/\s/g, '+')
      const city = listing.city?.replace(/\s+/g, '+')
      const state = listing.state?.replace(/\s+/g, '+')
      const zipCode = listing.zipcode?.replace(/\s+/g, '+')
      const country = listing.country?.replace(/\s+/g, '+')
      const addressUrl = `${address1},${city},${state},${zipCode},${country}`
      if (addressUrl.length < 5) {
        addNotification({ message: 'Geocode Cleared', timeout: 5000, type: 'success' })
        return { ...listing, geocode: { lat: 0, lng: 0, geohash: 0 } }
      }
      const dbEntryPromise = await firebase.firestore().collection('listings').doc(listing.guid).get()
      if (dbEntryPromise.exists) {
        const dbEntry = dbEntryPromise.data()
        const dbEntryAddress1 = dbEntry.address1?.replace(/\s/g, '+')
        const dbEntryCity = dbEntry.city?.replace(/\s+/g, '+')
        const dbEntryState = dbEntry.state?.replace(/\s+/g, '+')
        const dbEntryZipCode = dbEntry.zipcode?.replace(/\s+/g, '+')
        const dbEntryCountry = dbEntry.country?.replace(/\s+/g, '+')
        const dbEntryAddressUrl = `${dbEntryAddress1},${dbEntryCity},${dbEntryState},${dbEntryZipCode},${dbEntryCountry}`
        if (addressUrl === dbEntryAddressUrl) {
          return listing
        }
      }
      await axios.post(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressUrl}&key=${process.env.VUE_APP_GEOCODE_API_KEY}`)
        .then((res) => {
          addNotification({ message: `${listing.contact} Geocoded`, timeout: 5000, type: 'success' })
          const lat = res.data.results[0]?.geometry?.location.lat || 0
          const lng = res.data.results[0]?.geometry?.location.lng || 0
          const hash = geofire.geohashForLocation([lat, lng])
          geoListing = { ...listing, geocode: { lat, lng, geohash: hash } }
        })
        .catch((error) => {
          console.log(error)
          console.log(listing)
          addNotification({ message: `Geocode error on ${listing.contact}, please simplify the address once the upload is complete.`, timeout: null, type: 'error' })
          geoListing = { ...listing, geocode: { lat: 0, lng: 0, geohash: 0 } }
        })
      return geoListing
    } catch (error) {
      console.log(error)
      console.log(listing)
      addNotification({ message: `Geocode Error: ${error}`, timeout: null, type: 'error' })
    }
  },

  async upsertGeoCsv (context, geoCsv) {
    try {
      await context.commit('setUpsertCount', 0)
      let batch = firebase.firestore().batch()
      for (const i in geoCsv) {
        await context.commit('setUpsertCount', (parseInt(i) + 1))
        const docRef = await firebase.firestore().collection('listings').doc(geoCsv[i].guid)
        await batch.set(docRef, { ...geoCsv[i], timestamp: firebase.firestore.Timestamp.now() })
        if (context.state.upsertCount % 450 === 0) {
          await batch.commit()
          batch = firebase.firestore().batch()
          await context.commit('setProcessCount', parseInt(context.state.processCount + 450))
        } else if (context.state.upsertCount === context.state.uploadedCsvLength) {
          await batch.commit()
          await context.commit('setProcessCount', parseInt(context.state.uploadedCsvLength) * 2)
        }
      }
      context.commit('setUpsertingCsv', false)
      addNotification({ message: 'CSV Upload Complete', timeout: null, type: 'success' })
    } catch (error) {
      console.log(error)
      context.commit('setUpsertingCsv', false)
      addNotification({ message: `Upload CSV Error: ${error}`, timeout: null, type: 'error' })
    }
  },

  async upsertListing (context, geoListing) {
    try {
      await firebase.firestore().collection('listings').doc(geoListing.guid).set({ ...geoListing, timestamp: firebase.firestore.Timestamp.now() })
      context.commit('updateListing', geoListing)
      addNotification({ message: `${geoListing.contact} Updated`, timeout: 5000, type: 'success' })
    } catch (error) {
      console.log(error)
      addNotification({ message: `Update Listing Error: ${error}`, timeout: null, type: 'error' })
    }
  },

  async deleteListing (context, listing) {
    try {
      await firebase.firestore().collection('listings').doc(listing.guid).delete()
      await context.commit('removeListing', listing)
      addNotification({ message: `${listing.contact} Deleted`, timeout: 5000, type: 'success' })
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async browserLocate (context) {
    try {
      context.commit('toggleLocatingBrowser')
      navigator.geolocation.getCurrentPosition(
        position => {
          const coordsUrl = `${position.coords.latitude},${position.coords.longitude}`
          axios.post(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordsUrl}&key=${process.env.VUE_APP_GEOCODE_API_KEY}`)
            .then(async (res) => {
              const zipcode = res.data.results[0].address_components.find(function (component) {
                return component.types[0] === 'postal_code'
              })
              context.commit('setBrowserLocation', position)
              context.commit('setBrowserZipcode', zipcode.long_name)
              context.commit('setMapCenter', position)
              context.commit('clearZipcode')
              context.commit('setZipcode', zipcode.long_name)
              if (context.state.radius > 999) context.commit('setLocalMap')
              await context.dispatch('geohashRadius')
              context.commit('toggleLocatingBrowser')
            })
            .catch((error) => {
              console.log(error)
              addNotification({ message: `Error: ${error.message}`, timeout: 5000, type: 'error' })
            })
        },
        error => {
          context.commit('toggleLocatingBrowser')
          console.log(error.message)
          addNotification({ message: `Error: ${error.message}`, timeout: 5000, type: 'error' })
        }
      )
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
    }
  },

  async geohashRadius (context) {
    return new Promise((resolve, reject) => {
      try {
        const center = [context.state.mapCenter.lat, context.state.mapCenter.lng]
        const radiusInM = (context.state.radius * 1609.344) // to meters
        const bounds = geofire.geohashQueryBounds(center, radiusInM)
        const promises = []
        for (const bound of bounds) {
          const query = firebase.firestore().collection('listings').orderBy('geocode.geohash').startAt(bound[0]).endAt(bound[1])
          promises.push(query.get())
        }
        Promise.all(promises).then((snapshots) => {
          const matchingDocs = []
          for (const snap of snapshots) {
            for (const doc of snap.docs) {
              const lat = doc.get('geocode.lat')
              const lng = doc.get('geocode.lng')
              const distanceInKm = geofire.distanceBetween([lat, lng], center)
              const distanceInM = (distanceInKm * 1000) // to meters
              if (distanceInM <= radiusInM) {
                matchingDocs.push(doc)
              }
            }
          }
          return matchingDocs
        }).then((matchingDocs) => {
          const docs = matchingDocs.map(doc => doc.data())
          function calculateDistance (lat1, lon1, lat2, lon2) {
            var radlat1 = Math.PI * lat1 / 180
            var radlat2 = Math.PI * lat2 / 180
            var theta = lon1 - lon2
            var radtheta = Math.PI * theta / 180
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
            dist = Math.acos(dist)
            dist = dist * 180 / Math.PI
            dist = dist * 60 * 1.1515
            dist = dist * 1.609344 // to KM
            return dist
          }
          for (const i in docs) {
            docs[i].distance = calculateDistance(center[0], center[1], docs[i].geocode.lat, docs[i].geocode.lng)
          }
          docs.sort(function (a, b) {
            return a.distance - b.distance
          })
          context.commit('setListings', docs)
          resolve()
        })
      } catch (error) {
        console.log(error)
        addNotification({ message: `Error: ${error}`, timeout: 5000, type: 'error' })
        reject(error)
      }
    })
  }
}
