<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="159px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#ffffff" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.9900990099009901s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
    </circle>
    <!-- [ldio] generated by https://loading.io/ --></svg>
  </div>
</template>

<script>
export default {
  name: 'AppSpinnerDualRing'
}
</script>

<style scoped>

</style>
