const listingsByPage = perPage => ({
  data () {
    return {
      page: 1,
      perPage: perPage
    }
  },
  methods: {
    setDefaultPage () {
      this.page = 1
    }
  },
  computed: {
    listingsByPage () {
      this.$store.dispatch('listingsByPage', { listings: this.$store.state.listings, page: this.page, perPage: this.perPage })
      if (this.$store.state.listingsChunk.length > 0) {
        return this.$store.state.listingsChunk
      } else {
        this.setDefaultPage()
        this.$store.dispatch('listingsByPage', { listings: this.$store.state.listings, page: 1, perPage: this.perPage })
        return this.$store.state.listingsChunk
      }
    },
    listingCount () {
      return this.$store.state.listings.length
    },
    totalPages () {
      if (!this.listingCount) return 0
      return Math.ceil(this.listingCount / this.perPage)
    }
  }
})

export default listingsByPage
