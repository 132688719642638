<template>
  <VeeForm class="bg-gray-100 shadow-md rounded px-8 py-6 relative block">
    <div class="flex justify-between">
      <div class="flex-1">
        <AppFormField v-model="form.firstname" name="firstname" label="First Name" class="w-11/12"/>
        <AppFormField v-model="form.lastname" name="lastname" label="Last Name" class="w-11/12"/>
        <AppFormField v-model="form.contact" name="contact" label="Contact" class="w-11/12"/>
        <AppFormField v-model="form.title" name="title" label="Title" class="w-11/12"/>
        <AppFormField v-model="form.phone" name="phone" label="Phone" class="w-11/12"/>
      </div>
      <div class="flex-1">
        <AppFormField v-model="form.company" name="company" label="Company" class="w-11/12"/>
        <AppFormField v-model="form.department" name="department" label="Department" class="w-11/12"/>
        <AppFormField v-model="form.geocode.lat" name="latitude" label="Latitude" class="w-11/12 bg-gray-200" disabled/>
        <AppFormField v-model="form.geocode.lng" name="longitude" label="Longitude" class="w-11/12 bg-gray-200" disabled/>
        <AppFormField v-model="form.guid" name="guid" label="GUID" rules="required|guid" class="w-11/12"/>
      </div>
      <div class="flex-1">
        <AppFormField v-model="form.address1" name="address1" label="Address1" class="w-11/12"/>
        <AppFormField v-model="form.city" name="city" label="City" class="w-11/12"/>
        <AppFormField v-model="form.state" name="state" label="State" class="w-11/12"/>
        <AppFormField v-model="form.country" name="country" label="Country" class="w-11/12"/>
        <AppFormField v-model="form.zipcode" name="zipcode" label="Zipcode" class="w-11/12"/>
      </div>
    </div>
  </VeeForm>
</template>

<script>
export default {
  name: 'EditListing',
  props: {
    listing: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        ...this.listing
      }
    }
  },
  methods: {
    async submit () {
      const geoListing = await this.$store.dispatch('geocodeListing', this.form)
      await this.$store.dispatch('upsertListing', geoListing)
    }
  }
}
</script>

<style scoped>

</style>
