<template>
  <main class="mb-8">
    <Search />
    <div class="max-w-screen-xl mx-auto px-4">
      <div class="-mx-4 flex flex-wrap py-6">
        <aside class="w-full p-4 md:w-1/3 md:order-last">
          <div class="sticky top-0 w-full md:pt-8 lg:pl-4 xl:pl-8 ">
            <Map />
          </div>
        </aside>
        <main class="w-full p-4 md:w-2/3 md:order-first" role="main">
          <List />
        </main>
      </div><!-- /row -->
    </div><!-- /container -->
  </main>
</template>

<script>
import Search from '@/components/Search'
import List from '@/components/List'
import Map from '@/components/Map'
import asynDataStatus from '@/mixins/asyncDataStatus'
export default {
  name: 'Home',
  components: {
    Search,
    List,
    Map
  },
  mixins: [asynDataStatus],
  computed: {
    authUser () {
      return this.$store.getters.authUser
    }
  },
  async created () {
    this.$store.dispatch('browserLocate')
    await this.$store.dispatch('geohashRadius')
    this.asyncDataStatus_fetched()
  }
}
</script>

<style scoped>
main {
  max-width: 1400px;
  margin: auto;
}

</style>
