<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <UploadIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg leading-6 font-bold text-gray-900">
                    Upload CSV
                  </DialogTitle>
                  <div class="pt-8 pb-6 flex justify-center align-middle">
                    <div>
                      <UploadCsv ref="uploadCsv"/>
                    </div>
                    <div class="mt-16 ml-8">
                      <div v-if="geocodeCount !== null">
                        Geocoded {{geocodeCount}} / {{uploadedCsvLength}}<span v-if="!doneUpserting"><AppLoadingDots/></span>
                      </div>
                      <div v-if="upsertCount !== null">
                        Updated {{upsertCount}} / {{uploadedCsvLength}}<span v-if="!doneUpserting"><AppLoadingDots/></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" :disabled="this.submitted" class="disabled:opacity-50 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-bold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" @click="submit">
                Submit
              </button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">
                Close
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import UploadCsv from '@/components/UploadCsv'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { UploadIcon } from '@heroicons/vue/outline'
export default {
  name: 'DeleteListingModal',
  components: {
    UploadCsv,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    UploadIcon
  },
  props: {
    open: { type: Boolean, required: true }
  },
  data () {
    return {
      submitted: false
    }
  },
  methods: {
    async close () {
      if (this.$store.state.upsertCount !== this.$store.state.uploadedCsvLength) {
        this.$emit('toggleUploadCsvModal')
      } else {
        this.$emit('toggleUploadCsvModal')
        this.submitted = !this.submitted
        this.$store.commit('clearProcessCount')
        this.$store.commit('setGeocodeCount', null)
        this.$store.commit('setUpsertCount', null)
        this.$store.commit('setUploadedCsvLength', 0)
      }
    },
    async submit () {
      this.submitted = !this.submitted
      await this.$refs.uploadCsv.submit()
    }
  },
  computed: {
    uploadedCsvLength () {
      return this.$store.state.uploadedCsvLength
    },
    geocodeCount () {
      return this.$store.state.geocodeCount
    },
    upsertCount () {
      return this.$store.state.upsertCount
    },
    doneUpserting () {
      return this.$store.state.uploadedCsvLength === this.$store.state.upsertCount
    }
  }
}
</script>
