import Home from '@/pages/Home'
import NotFound from '@/pages/NotFound'
import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  await store.dispatch('initAuthentication')
  store.dispatch('unsubscribeAllSnapshots')
})

export default router
