<template>
    <div class="max-w-screen-xl mx-auto px-4 pt-6 max-w-screen-xl mx-auto px-4 pt-6 grid grid-cols-12 gap-4">
      <div class="col-span-6 md:col-span-2">
        <p class="p-0">Within</p>
        <button @click="radiusDropdownOpen = !radiusDropdownOpen" :disabled="locatingBrowser" class="disabled:opacity-50 inline-block z-10 rounded-md bg-white focus:outline-none">
          <b>
            <div v-if="radius" class="inline">
              {{radius}} Miles
            </div>
            <div v-else class="inline">
              &infin; Miles
            </div>
            <ChevronDownIcon class="inline h-5 w-5" />
          </b>
        </button>
          <div v-show="radiusDropdownOpen" class="absolute mt-2 bg-white border border-gray-200 border-solid rounded-md shadow-xl z-20 w-36">
            <button @click="setSearchZipcodeRadius(10)" value="10" class="active selection block px-3 py-2 w-full text-sm text-gray-700 text-left hover:bg-blue-500 hover:text-white">
              10 Miles
            </button>
            <button @click="setSearchZipcodeRadius(25)" value="25" class="selection block px-3 py-2 w-full text-sm text-gray-700 text-left hover:bg-blue-500 hover:text-white">
              25 Miles
            </button>
            <button @click="setSearchZipcodeRadius(50)" value="50" class="selection block px-3 py-2 w-full text-sm text-gray-700 text-left hover:bg-blue-500 hover:text-white">
              50 Miles
            </button>
            <button @click="setSearchZipcodeRadius(150)" value="150" class="selection block px-3 py-2 w-full text-sm text-gray-700 text-left hover:bg-blue-500 hover:text-white">
              150 Miles
            </button>
          </div>
          <p class="inline ml-1">of</p>
        </div>
      <input v-model="zipcode" v-on:blur="updateZipcode" v-on:keyup.enter="updateZipcode" :disabled="locatingBrowser" type="text" id="zipcode" class="col-span-6 md:col-span-3 px-3 py-2 border border-gray-200 rounded-lg z-0 focus:shadow focus:outline-none" placeholder="Postal Code or City">
      <input v-model="searchNameInput" v-on:keyup.enter="submitSearch" :disabled="locatingBrowser" type="text" class="col-span-12 md:col-span-3 px-3 py-2 border border-gray-200 rounded-lg z-0 focus:shadow focus:outline-none" placeholder="Search by name...">
      <button @click="submitSearch" :disabled="locatingBrowser" class="disabled:opacity-50 col-span-6 md:col-span-2 text-white font-bold rounded-lg bg-blue-500 hover:bg-blue-700 px-3 py-2 w-full"><SearchIcon class="inline -mt-1 -ml-1 mr-2 h-5 w-5 text-white" />Search</button>
      <button @click="resetSearch" :disabled="locatingBrowser" class="disabled:opacity-50 col-span-6 md:col-span-2 text-white font-bold rounded-lg bg-gray-500 hover:bg-gray-700 px-3 py-2 w-full"><BanIcon class="inline -mt-1 -ml-1 mr-2 h-5 w-5 text-white" />Reset</button>
    </div>

</template>

<script>
import { SearchIcon, BanIcon, ChevronDownIcon } from '@heroicons/vue/outline'
export default ({
  name: 'Search',
  components: {
    SearchIcon,
    BanIcon,
    ChevronDownIcon
  },
  data () {
    return {
      radiusDropdownOpen: false,
      searchNameInput: ''
    }
  },
  methods: {
    async setSearchZipcodeRadius (radius) {
      if (this.$store.state.radius > 999) {
        this.radiusDropdownOpen = !this.radiusDropdownOpen
        alert('Please search by location before adjusting the search radius.')
      } else {
        await this.$store.commit('setRadius', radius)
        this.submitSearch()
        this.radiusDropdownOpen = !this.radiusDropdownOpen

        // Toggle active selection CSS
        const selections = document.getElementsByClassName('selection')
        for (const i in selections) {
          if (selections[i].value === radius.toString()) {
            selections[i].classList.add('active')
          } else {
            selections[i].classList.remove('active')
          }
        }
      }
    },
    updateZipcode () {
      if (this.$store.state.radius > 999) {
        // Toggle active selection CSS
        const selections = document.getElementsByClassName('selection')
        for (const i in selections) {
          if (selections[i].value === '10') {
            selections[i].classList.add('active')
          }
        }
      }
      const zipcode = document.getElementById('zipcode').value
      this.$store.commit('setZipcode', zipcode)
      this.$store.dispatch('listingsBySearch', { searchZipcodeInput: zipcode, searchNameInput: this.searchNameInput })
    },
    submitSearch () {
      const zipcode = this.$store.state.zipcode
      this.$store.commit('setZipcode', zipcode)
      this.$store.dispatch('listingsBySearch', { searchZipcodeInput: zipcode, searchNameInput: this.searchNameInput })
    },
    async resetSearch () {
      await this.$store.commit('setZipcode', '')
      this.searchNameInput = ''
      await this.$store.commit('clearMapCenter')
      this.submitSearch()

      // Toggle active selection CSS
      const selections = document.getElementsByClassName('selection')
      for (const i in selections) {
        selections[i].classList.remove('active')
      }
    }
  },
  computed: {
    zipcode () {
      return this.$store.state.zipcode
    },
    radius () {
      if (this.$store.state.radius > 999) {
        return false
      } else {
        return this.$store.state.radius
      }
    },
    locatingBrowser () {
      return this.$store.state.locatingBrowser
    }
  }
})
</script>

<style scoped>
.active {
  background: #3B82F6 !important;
  color: white !important;
}
</style>
