<template>
  <div class="inline">
    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="17px" height="17px" viewBox="0 0 128 128" xml:space="preserve"><path d="M0 128V83h17.25v27.75h93.5V83H128v45H0z" fill="#ffffff"/><g><path d="M80.92 210.95v-51.27h18.15L64 113.18l-35.07 46.5h18.15v51.27h33.84z" fill="#ffffff"/><animateTransform attributeName="transform" type="translate" from="0 0" to="0 -220" dur="800ms" repeatCount="indefinite"/></g></svg>
  </div>
</template>

<script>
export default {
  name: 'AppUploadLoader'
}
</script>

<style scoped>

</style>
