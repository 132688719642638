
export default {
  setAuthObserverUnsubscribe (state, unsubscribe) {
    state.authObserverUnsubscribe = unsubscribe
  },

  setAuthUserUnsubscribe (state, unsubscribe) {
    state.authUserUnsubscribe = unsubscribe
  },

  setAuthId (state, id) {
    state.authId = id
  },

  setAllListingsSnapshotUnsubscribe (state, unsubscribe) {
    state.allListingsUnsubscribe = unsubscribe
  },

  appendUnsubscribe (state, unsubscribe) {
    state.unsubscribes.push(unsubscribe)
  },

  clearAllUnsubscribes (state) {
    state.unsubscribes = []
  },

  setAllListingsSnapshotFetched (state, bool) {
    state.allListingsSnapshotFetched = bool
  },

  setAllListings (state, listings) {
    if (listings) {
      state.allListings = [...listings]
    } else {
      state.allListings = []
    }
  },

  setListings (state, listings) {
    if (listings) {
      state.listings = [...listings]
    } else {
      state.listings = []
    }
  },

  updateListing (state, updatedListing) {
    const index = state.listings.findIndex((listing) => listing.guid === updatedListing.guid)
    if (index > -1) {
      state.listings.splice(index, 1, updatedListing)
    } else {
      state.listings.unshift(updatedListing)
    }
  },

  removeListing (state, listing) {
    const index = state.listings.indexOf(listing)
    if (index > -1) {
      state.listings.splice(index, 1)
    }
  },

  clearListings (state) {
    state.listings = []
  },

  setListingsChunk (state, listingsChunk) {
    if (listingsChunk) {
      state.listingsChunk = [...listingsChunk]
    } else {
      state.listingsChunk = []
    }
  },

  clearListingsChunk (state) {
    state.listingsChunk = []
  },

  toggleLocatingBrowser (state) {
    state.locatingBrowser = !state.locatingBrowser
  },

  setBrowserLocation (state, position) {
    state.browserLocation = { lat: position.coords.latitude, lng: position.coords.longitude }
  },

  setBrowserZipcode (state, zipcode) {
    state.browserZipcode = zipcode
  },

  setMapCenter (state, position) {
    state.mapCenter = { lat: position.coords.latitude, lng: position.coords.longitude }
  },

  clearMapCenter (state) {
    state.mapCenter = { lat: 0, lng: 0 }
  },

  setZipcode (state, zipcode) {
    state.zipcode = zipcode
  },

  clearZipcode (state) {
    state.zipcode = null
  },

  setRadius (state, radius) {
    state.radius = radius
    switch (radius) {
      case 10:
        state.zoom = 10.3
        break
      case 25:
        state.zoom = 9
        break
      case 50:
        state.zoom = 8
        break
      case 150:
        state.zoom = 6.45
        break
      default:
        state.zoom = 9
    }
  },

  setGlobalMap (state) {
    state.zoom = 2
    state.radius = 99999
    state.zipcode = ''
    state.mapCenter = ({ lat: 40.0141127, lng: -105.2370652 })
  },

  setLocalMap (state) {
    state.zoom = 10.3
    state.radius = 10
  },

  setUpsertingCsv (state, bool) {
    state.upsertingCsv = bool
  },

  setUploadedCsvLength (state, length) {
    state.uploadedCsvLength = length
  },

  setProcessCount (state, count) {
    state.processCount = count
  },

  incrementProcessCount (state) {
    state.processCount++
  },

  clearProcessCount (state) {
    state.processCount = 0
  },

  setGeocodeCount (state, count) {
    state.geocodeCount = count
  },

  incrementGeocodeCount (state) {
    state.processCount++
  },

  setUpsertCount (state, count) {
    state.upsertCount = count
  }
}
