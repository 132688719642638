<template>
  <div>
    <div class="mb-8">
      <MapControls/>
    </div>
    <GMapMap
    class="h-96"
    :center="mapCenter"
    :zoom="zoom"
    :options="{
      mapTypeControl: false,
      streetViewControl: false,
      gestureHandling: 'cooperative',
      zoomControl: !locatingBrowser,
      fullscreenControl: !locatingBrowser,
    }"
    >
      <GMapCircle
          :radius="milesToMeters"
          :center="mapCenter"
          :options="{
            strokeColor: '#FF0000',
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0
          }"
      />
      <div v-if="!tooManyListings">
        <div v-for="(listing) in listings" :key="listing.guid">
          <GMapMarker @click="openMarker(listing.guid)" @closeclick="openMarker(null)" :options="markerOptions(listing)" >
            <GMapInfoWindow :opened="openedMarkerId === listing.guid">
              <div class="text-center">
                <p><b>{{listing.contact}}</b> {{listing.title}}</p>
                <p >{{listing.phone}}</p>
                <br>
                <p>{{listing.company}}</p>
                <p>{{listing.department}}</p>
                <br>
                <p>{{listing.address1}}</p>
                <p>{{listing.city}} {{listing.state}} {{listing.country}} {{listing.zipcode}}</p>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
        </div>
      </div>
    </GMapMap>
  </div>
</template>

<script>
import MapControls from '@/components/MapControls'
export default ({
  name: 'Map',
  components: {
    MapControls
  },

  data () {
    return {
      openedMarkerId: null
    }
  },
  methods: {
    markerOptions (listing) {
      if (listing.geocode.lat !== 0 && listing.geocode.lng !== 0) {
        return { position: { lat: listing.geocode.lat, lng: listing.geocode.lng }, title: listing.company, clickable: true }
      }
    },
    openMarker (id) {
      this.openedMarkerId = id
    }
  },
  computed: {
    mapCenter () {
      return this.$store.state.mapCenter
    },
    tooManyListings () {
      return (this.$store.state.listings.length > 500)
    },
    zoom () {
      return this.$store.state.zoom
    },
    listings () {
      return this.$store.state.listings
    },
    milesToMeters () {
      return (this.$store.state.radius * 1609.344) // to meters
    },
    locatingBrowser () {
      return this.$store.state.locatingBrowser
    }
  }
})
</script>

<style scoped>

</style>
