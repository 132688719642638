<template>
  <header id="top-wrapper">
    <div id="top-elements" class="container_24">
      <div id="logo" class="grid_14">
        <h1><a title="Klose Training" href="https://klosetraining.com">Klose Training</a></h1>
      </div>
      <div id="slogan" class="grid_17">Lymphedema Certification and Online Education</div>
      <div class="phone-number grid_7 prefix_17">
        <div class="phone-number-padding">
          <a href="http://www.klosetrainingstore.com/Default.asp" target="_blank" class="redlink">REGISTER NOW for courses!</a><br><a class="greylink" href="http://klosetrainingonline.com/" target="_blank">Online Course Login</a> </div>
      </div>
    </div>
    <div class="clear"></div>
    <nav id="main-menu">
      <div id="dropdown-holder" class="container_24">
        <div id="navigation-menu" class="navigation-menu"><a id="sticky-menu-logo" href="https://klosetraining.com"><img src="https://klosetraining.com/wp-content/uploads/2015/08/Klose_Cert_logo_2015_339x75.png" alt="logo" height="40"></a>
          <ul id="main-top-menu" class="sf-menu sf-js-enabled">
            <li id="menu-item-20" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-20"><a href="http://klosetraining.com/"><span>Home</span></a></li>
            <li id="menu-item-9119" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9119"><a href="https://klosetraining.com/schedule/" ><span>Schedule</span></a></li>
            <li id="menu-item-414" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-414"><a href="https://klosetraining.com/course/in-classroom/" class="sf-with-ul"><span>Courses</span></a>
            </li>
            <li id="menu-item-10290" class="menu-item menu-item-type-post_type current-menu-item current_page_item menu-item-object-page menu-item-10290"><a href="/"  aria-current="page"><span>Therapist Directory</span></a></li>
            <li id="menu-item-420" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-420"><a href="http://klosetraining.com/about-us/klose-training/" class="sf-with-ul"><span>About Us</span></a>
            </li>
            <li id="menu-item-22" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-22"><a href="https://klosetraining.com/contact/"><span>Contact</span></a></li>
            <li id="menu-item-439" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-439"><a href="https://klosetraining.com/resources/self-care-videos/" class="sf-with-ul"><span>Resources</span></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <section id="page-content-title">
    <div id="page-content-header" class="container_24">
      <div id="page-title">
        <h1 class="pagetitle">CLT - Certified Lymphedema Therapist Directory</h1>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>

</style>
